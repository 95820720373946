import instance from "../Instance";
import {
  ACTION_FEEDBACK,
  DELETE_FEEDBACK,
  GET_FEEDBACK_LIST,
  GET_REPORTS_LIST,
} from "./FeedbackEndPoints";

export function getFeedbackList(currentPage, limit, search) {
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(
    GET_FEEDBACK_LIST + `?page=${currentPage}&limit=${limit}`,
    {
      headers: myHeaders,
    }
  );
}

export function deleteFeedback(id) {
  const data = localStorage.getItem("closerDetails");

  return instance.delete(DELETE_FEEDBACK + `?feedbackId=${id}`, {
    headers: {
      Authorization: `Bearer ${data}`,
    },
  });
}

export function ActionFeedback(id, toggle) {
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  const postData = { feedbackId: id, status: toggle };
  return instance.put(ACTION_FEEDBACK, postData, {
    headers: myHeaders,
  });
}

/////////////////===========================reports====================///////////////////

export function getReportsList(currentPage, limit, status) {
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  return instance.get(
    GET_REPORTS_LIST + `?page=${currentPage}&limit=${limit}&status=${status}`,
    {
      headers: myHeaders,
    }
  );
}

export function reportAction(id, status) {
  const data = localStorage.getItem("closerDetails");
  const myHeaders = {
    Authorization: `Bearer ${data}`,
  };
  const postData = { reportId: id, status: status };
  return instance.put(GET_REPORTS_LIST, postData, {
    headers: myHeaders,
  });
}
