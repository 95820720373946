/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import icon1 from "../../../images/icon1.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }
  state = {
    loveEmoji: false,
  };
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    /// Active menu
    let dashBoard = [""],
      user = ["user-management"],
      qna = ["QnA-listing", "QnA-create", "QnA-frequency"],
      imgCard = [
        "image-card-listing",
        "image-card-create",
        "image-card-frequency",
      ],
      quoteCard = [
        "quote-card-listing",
        "quote-card-create",
        "quote-card-frequency",
      ],
      hornyCard = [
        "horny-card-listing",
        "horny-card-create",
        "horny-card-frequency",
      ],
      feedback = [
        "feedback-list",
      ],
      report = [
        "reports-list",
      ],
      notification = ["push-notification"];

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className="ai-icon" to="">
                <i className="flaticon-381-networking"></i>
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>
            <li className={`${user.includes(path) ? "mm-active" : ""}`}>
              <Link to="user-management" className="ai-icon">
                <i className="flaticon-381-user-9"></i>
                <span className="nav-text">User Management</span>
              </Link>
            </li>
            <li className={`${qna.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#">
                <i className="flaticon-381-notepad"></i>
                <span className="nav-text forms">QnA Cards</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className={`${path === "QnA-listing" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/QnA-listing"
                  >
                    Listing
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "QnA-create" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/QnA-create"
                  >
                    Create
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "QnA-frequency" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/QnA-frequency"
                  >
                    Frequency
                  </Link>
                </li>
              </ul>
            </li>
            <li className={`${imgCard.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#">
                <i className="flaticon-381-picture"></i>
                <span className="nav-text forms">Image Cards</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className={`${
                      path === "image-card-listing" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/image-card-listing"
                  >
                    Listing
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === "image-card-create" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/image-card-create"
                  >
                    Create
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === "image-card-frequency" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/image-card-frequency"
                  >
                    Frequency
                  </Link>
                </li>
              </ul>
            </li>
            <li className={`${quoteCard.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#">
                <i className="flaticon-381-picture-1"></i>
                <span className="nav-text forms">Quote Cards</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className={`${
                      path === "quote-card-listing" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/quote-card-listing"
                  >
                    Listing
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === "quote-card-create" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/quote-card-create"
                  >
                    Create
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === "quote-card-frequency" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/quote-card-frequency"
                  >
                    Frequency
                  </Link>
                </li>
              </ul>
            </li>
            <li className={`${hornyCard.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#">
                <i className="flaticon-381-picture-2"></i>
                <span className="nav-text forms">Horny Cards</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className={`${
                      path === "horny-card-listing" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/horny-card-listing"
                  >
                    Listing
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${
                      path === "horny-card-create" ? "mm-active" : ""
                    }`}
                    onClick={() => this.props.onClick()}
                    to="/horny-card-create"
                  >
                    Create
                  </Link>
                </li>
                
              </ul>
            </li>
            
            <li className={`${feedback.includes(path) ? "mm-active" : ""}`}>
              <Link to="feedback-list" className="ai-icon">
                <i className="flaticon-381-list"></i>
                {/* <img src={notifiIcon} /> */}
                <span className="nav-text">Feedback Management</span>
              </Link>
            </li>
            <li className={`${report.includes(path) ? "mm-active" : ""}`}>
              <Link to="reports-list" className="ai-icon">
                <i className="flaticon-381-warning"></i>
                {/* <img src={notifiIcon} /> */}
                <span className="nav-text">Reports Management</span>
              </Link>
            </li>
            
            <li className={`${notification.includes(path) ? "mm-active" : ""}`}>
              <Link to="push-notification" className="ai-icon">
                <i className="flaticon-381-notification"></i>
                {/* <img src={notifiIcon} /> */}
                <span className="nav-text">Notification</span>
              </Link>
            </li>
          </MM>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
