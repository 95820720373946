import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../components/Common/Spinner";

import dummy from "../../images/Profile_avatar_placeholder_large.png";
import { Link } from "react-router-dom";

export default function ReportDetails(props) {
  const data = props.location.state;
  const [loader, setLoader] = useState(false);
  const imgUrl = process.env.REACT_APP_IMAGE_BASEURL;
console.log(data)
  return (
    <div>
      <div className="page-titles">
        <h4>Report Details</h4>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="reports-list">Reports Management</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link>Report Details</Link>
          </li>
        </ol>
      </div>

          <Card className="mb-3" >
            <Card.Body>
              <Row>
                <Col xs={12} md={6} className="mb-3 mb-md-0">
                  <div className="mb-3">
                    <h5>Description</h5>
                    <p className="fs-14">{data?.description}</p>
                  </div>
                
                </Col>
                <Col xs={12} md={6}>
                  <Row>
                    {data?.files &&
                      data?.files?.map((image, index) => (
                        <Col xs={6} sm={4} key={index} className="mb-3">
                          <img
                            src={image ? imgUrl + image : dummy}
                            width="100%"
                            height="auto"
                            className="rounded"
                            alt="Feedback"
                          />
                        </Col>
                      ))}
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
    

      {loader && <Spinner />}
    </div>
  );
}
