import React, { useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge, Col, Button } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "../components/Common/Pagination";
import Spinner from "../components/Common/Spinner";
import {
  deleteFeedback,
  getReportsList,
  reportAction,
} from "../../services/Feedback/FeedbackService";
import ConfirmationModal from "../components/modal/ConfirmationModal";
import moment from "moment";
import { blockUser } from "../../services/User/UserService";

export default function ReportsManagement(props) {
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [listLength, setListlenght] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [status, setStatus] = useState("pending");
  const [limit, setLimit] = useState(100);
  const [confirmModal, setConfirmModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  const notifyTopRight = (success) => {
    toast.success(`✅ ${success}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const notifyError = (error) => {
    toast.error(`❌${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  function getTableData() {
    setLoader(true);
    getReportsList(currentPage, limit, status)
      .then((response) => {
        console.log(response.data);
        setUsers(response.data.data?.data);
        setLoader(false);
        const total = response.data.data?.count;
        setPageCount(Math.ceil(total / limit));
        setListlenght(response.data.data?.count);
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
        if (error.response.data.statusCode === 401) {
          localStorage.clear("closerDetails");
          props.history.push("/");
        }
      });
  }

  function onBlock(id, toggle) {
    setLoader(true);
    blockUser(id, toggle)
      .then((response) => {
        notifyTopRight(response.data.message);
        getTableData();
        setLoader(false);

        console.log(response);
      })
      .catch((error) => {
        console.log(error.response, "helooooooooo");
        setLoader(false);
        notifyError(error.response?.data?.data);
        if (error.response?.data?.statusCode === 401) {
          localStorage.clear("closerDetails");
          props.history.push("/");
        }
      });
  }
  function onAction(id, status) {
    setLoader(true);
    reportAction(id, status)
      .then((response) => {
        notifyTopRight(response.data.message);
        getTableData();
        setLoader(false);
        console.log(response);
      })
      .catch((error) => {
        console.log(error.response, "helooooooooo");
        setLoader(false);
        notifyError(error.response?.data?.data);
        if (error.response?.data?.statusCode === 401) {
          localStorage.clear("closerDetails");
          props.history.push("/");
        }
      });
  }

  useEffect(() => {
    getTableData();
  }, [currentPage, limit, status]);

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <PageTitle activeMenu="Reports List" motherMenu="Reports Management" />
      <Col>
        <Card>
          <Card.Header>
            <div></div>
            <div>
              <label>Filter by status</label>
              <select
                className="form-control"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value={"pending"}>Pending</option>
                <option value={"approved"}>Approve</option>
                <option value={"ignore"}>Ignore</option>
              </select>
            </div>
          </Card.Header>
          <Card.Body>
            <Table
              responsive
              className="mostly-customized-scrollbar"
              //   style={{ height: "500px" }}
            >
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>Reported By</strong>
                  </th>
                  <th>
                    <strong>Reported To</strong>
                  </th>
                  <th>
                    <strong>Created At</strong>
                  </th>
                  <th>
                    <strong>description</strong>
                  </th>
                  <th>
                    <strong>STATUS</strong>
                  </th>
                  {status === "ignore" ? (
                    ""
                  ) : (
                    <th>
                      <strong>ACTION</strong>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {users?.map((item, i) => (
                  <tr key={i}>
                    <td>
                      {item.from?.name} <br />
                      {item.from?.email} <br />
                      {item.from?.phoneNumber}
                    </td>
                    <td>
                      {item.to?.name} <br />
                      {item.to?.email} <br />
                      {item.to?.phoneNumber}
                    </td>

                    <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-primary py-2 px-3 fs-12"
                        onClick={() =>
                          props.history.push({
                            pathname: "/report-details",
                            state: item,
                          })
                        }
                      >
                        View
                      </button>
                    </td>
                    <td>
                      {item?.status === "pending" ? (
                        <Badge variant="warning light">Pending</Badge>
                      ) : item?.status === "ignore" ? (
                        <Badge variant="danger light">Ignore</Badge>
                      ) : (
                        <Badge variant="success light">Approve</Badge>
                      )}
                    </td>
                    {item?.status === "ignore" ? (
                      ""
                    ) : (
                      <td>
                        {item?.status === "approved" &&
                        !item?.isRepeatOffender ? (
                          ""
                        ) : (
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="info light"
                              className="light sharp btn btn-info i-false"
                            >
                              {svg1}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {item?.status === "approved" &&
                              item?.isRepeatOffender ? (
                                <Dropdown.Item
                                  onClick={() => onBlock(item?.to?._id, 1)}
                                >
                                  Block
                                </Dropdown.Item>
                              ) : (
                                <>
                                  <Dropdown.Item
                                    onClick={() => onBlock(item?.to?._id, 1)}
                                  >
                                    Block
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      onAction(item._id, "approved")
                                    }
                                  >
                                    Approve
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() => onAction(item._id, "ignore")}
                                  >
                                    Ignore
                                  </Dropdown.Item>
                                </>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
            {users?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            {users?.length === 0 ? (
              ""
            ) : (
              <div className="card-footer clearfix">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="dataTables_info">
                    Showing {currentPage * limit + 1} to{" "}
                    {listLength > (currentPage + 1) * limit
                      ? (currentPage + 1) * limit
                      : listLength}{" "}
                    of {listLength} entries
                  </div>
                  <Pagination
                    pageCount={pageCount}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div>
                <div>
                  <label className="mr-2">Result Per page : </label>
                  <select
                    className=""
                    value={limit}
                    onChange={(e) => setLimit(e.target.value)}
                  >
                    <option>10</option>
                    <option>20</option>
                    <option>30</option>
                    <option>50</option>
                    <option>100</option>
                  </select>
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
      {loader && <Spinner />}
    </div>
  );
}
